import HeroImage from "../assets/HeroSectionHome.png";
import Navbar from "../components/shared/Navbar";

import Mail from "../assets/mail.svg";
import Location from "../assets/map-pin.svg";
import Footer from "../components/shared/Footer";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = JSON.stringify(formData);
    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbxH24ZcnUUb7T9cibs9IZtrGGpUl0yVrfK3rwCi4qgiYoYnMdWLxzOHPpYZ8KI-rNJ9/exec",
        {
          mode: "no-cors",
          method: "POST",
          body: form,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Your message has been saved");
      setFormData({ name: "", email: "", message: "" });
    } catch (error) {
      console.error("Error:", error);
      alert("Error submitting the form");
    }
  };

  return (
    <>
      <Navbar />
      <ToastContainer />

      <div className="relative  pt-40 min-h-screen">
        <img
          alt="background"
          src={HeroImage}
          className="absolute inset-0 -z-10 object-cover heroImage"
        />

        <div className="container mx-auto">
          <div className="flex justify-between items-stretch md:px-28 gap-4 md:gap-28 flex-col md:flex-row">
            <div className="flex-1 py-4 md:px-0 px-12">
              <h2 className="text-2xl font-bold text-white mb-4">Contact Us</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label
                    htmlFor="name"
                    className="block text-[1rem] font-medium text-white"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-[1rem] p-2"
                    placeholder="Your name"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-[1rem] font-medium text-white"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-[1rem] p-2"
                    placeholder="your.email@example.com"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="message"
                    className="block text-[1rem] font-medium text-white"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows={3}
                    value={formData.message}
                    onChange={handleInputChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-[1rem] p-2"
                    placeholder="Your message..."
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="inline-flex justify-center rounded-full border border-white bg-transparent py-2 px-8 text-[1rem] font-medium text-white shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 transition duration-300 ease-in-out transform hover:scale-110"
                >
                  Send
                </button>
              </form>
            </div>
            <div className="flex-1 flex items-center justify-center py-12">
              <div className=" bg-[#B79964] p-8 py-16 h-full flex flex-col justify-between">
                <div className="flex items-start gap-2 flex-col">
                  <img src={Mail} alt="mail" />
                  <p className="text-white text-xl">contact@cccsnv.com</p>
                </div>
                <div className="flex items-start gap-2 flex-col">
                  <img src={Location} alt="location" />
                  <p className="text-white text-xl">
                    101, 1st Floor, Elemental #337, Road No1, Malakunta,
                    Financial District, Hyderabad, Telangana - 500032
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
