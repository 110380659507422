"use client";

import React from "react";
import {
  ChartBarIcon,
  AcademicCapIcon,
  UserGroupIcon,
  BriefcaseIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/outline";
import { ReactComponent as Products } from "../../assets/Products.svg";
import { ReactComponent as Projects } from "../../assets/Projects.svg";
import { ReactComponent as Services } from "../../assets/Services.svg";
import { ReactComponent as Consulting } from "../../assets/Consulting.svg";
import { ReactComponent as Trainings } from "../../assets/Trainings.svg";

const values = [
  {
    title: "Products",
    description:
      "Providing cutting-edge education tech solutions, including resume-building courses and career tools, to fuel professional growth and success.",
    icon: <>{<Products />}</>,
  },
  {
    title: "Training",
    description:
      "Delivering specialized training in academics, employability skills, and corporate development, designed to boost knowledge and career readiness.",
    icon: <>{<Trainings />}</>,
  },
  {
    title: "Consulting",
    description:
      "Providing expert consulting in leadership development, maturity models, and metrics management to accelerate organizational growth and efficiency.",
    icon: <>{<Consulting />}</>,
  },
  {
    title: "Projects",
    description:
      "Executing projects in government, information technology, and business process outsourcing sectors, delivering customized solutions for complex challenges.",
    icon: <>{<Projects />}</>,
  },
  {
    title: "Services",
    description:
      "Providing comprehensive resource augmentation, hire-train-deploy solutions, and recruitment services to effectively address and meet diverse talent needs across various industries",
    icon: <>{<Services />}</>,
  },
];

const Values = () => {
  return (
    <div className="flex flex-col items-center justify-center py-24">
      <h2 className="text-4xl font-pt-sans font-bold mb-20">Our Values</h2>

      <div className="flex flex-wrap justify-center gap-5 gap-y-20 max-w-6xl">
        {values.map((value, index) => (
          <div
            key={index}
            className="flex w-[370px] flex-col items-center text-center [box-sizing:content-box]"
          >
            {value.icon}
            <h3 className="text-2xl text-[#253C86] font-pt-sans font-bold">
              {value.title}
            </h3>
            <p className="text-gray-500 leading-5 md:text-xl text-base font-normal font-pt-sans mt-2 mx-2">
              {value.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Values;
