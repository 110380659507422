'use client'


import HeroImage from "../../assets/HeroSectionHome.png"
import Navbar from "../shared/Navbar"

const HeroSection = () => {

  return (
    <div style={{height:"661px"}} className="bg-gray-900">
      
      <Navbar/>

      <div className="relative isolate pt-14">
        <img
          alt=""
          src={HeroImage}
          className="absolute inset-0 -z-10 object-cover heroImage"
        />
      
        <div className="lg:px-24 p-12 max-w-4xl py-32 sm:py-auto lg:py-auto">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
           
          </div>
          <div className="text-left">
            <h1 className="font-pt-sans mt-16 md:text-64px font-bold tracking-normal text-white text-5xl md:leading-4rem">
                Big Transformation <br/> Brave Actions
            </h1>
            <p className="font-pt-sans text-2xl mt-6 leading-8 text-gray-100">
            Educate and skill 100 million learners by 2028
            </p>
            
            <div className="relative bg-black bg-opacity-30 w-[180px] h-[46px] flex rounded-full px-3 py-1 mt-10 text-sm leading-6 text-gray-400 ring-1 ring-white hover:ring-white/20">
              
              <a href="#" className="font-regular text-xl font-pt-sans m-auto text-white">
                <span aria-hidden="true" className="absolute font-pt-sans inset-0" />
                Know More <span aria-hidden="true">&rarr;</span>
              </a>
            </div>

          </div>
        </div>
        
      </div>
    </div>
  )
}

export default HeroSection;