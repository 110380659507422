import React from "react";
import Card from "./Card";
import Government from "../../../assets/Government.png";
import InformationTechnology from "../../../assets/InfromationTechnology.png";
import BpoKpo from "../../../assets/BpoKpo.png";

const Projects = () => {
  const cardData = [
    {
      title: "Government",
      description:
        "We enhance public services with e-governance, citizen engagement, data management, and cybersecurity solutions.",
      src: Government,
    },
    {
      title: "Information Technology",
      description:
        "We create interactive platforms, intelligent tutoring, and content management to enhance education and learning",
      src: InformationTechnology,
    },
    {
      title: "BPO/KPO",
      description:
        "We streamline BPO and KPO operations with process automation, data management, analytics, and workflow optimization.",
      src: BpoKpo,
    },
  ];

  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-wrap justify-around">
        {cardData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            description={card.description}
            imageUrl={card.src}
          />
        ))}
      </div>
    </div>
  );
};

export default Projects;
