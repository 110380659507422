import React from "react";

const Card = ({ title, description, imageUrl }) => {
  return (
    <div className="max-w-[230px] overflow-hidden bg-white m-4">
      <img className="w-full rounded" src={imageUrl} alt={title} />
      <div className="py-4">
        <div className="font-regular font-pt-sans text-xl mb-2">{title}</div>
        <p className="text-gray-700 text-sm text-gray-400 font-pt-sans">{description}</p>
      </div>
      <div className="relative w-[142px] h-[38px] m-1 flex rounded-full px-3 py-1 mt-5 text-sm leading-6  ring-1 ring-black hover:ring-black/20">
              
              <a href="#" className="font-regular text-md font-pt-sans m-auto text-black">
                <span aria-hidden="true" className="absolute font-bold font-pt-sans inset-0" />
                Know More <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
    </div>
  );
};

export default Card;
