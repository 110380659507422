import React from 'react';
import Founder from "../../assets/Founder.png"

const AboutFounder = () => {
  return (
    <div className="mt-12 mb-12">
      <h2 className="text-4xl font-pt-sans font-bold text-center mb-8">About the founder</h2>
      <div 
        className="max-w-6xl md:mx-auto rounded-lg overflow-hidden bg-[#3953A4] bg-no-repeat bg-right bg-contain mx-5"
        style={{backgroundImage: `url(${Founder})`}}
      >
        <div className="flex flex-col md:flex-row md:items-start">
          <div className="md:w-3/4 text-white p-9 md:p-12">
            <h3 className="text-3xl font-pt-sans font-semibold mb-2">Srinagaveer V</h3>
            <p className="text-xl font-pt-sans mb-4">Founder, CCC Group</p>
            <p className="text-sm font-pt-sans hidden md:block md:text-lg">
              Founded in 2012, CCC specialized in technology such as skill development. Its
              subsidiary, CCC Digital India, offers software development and outsourcing. CCC
              has trained over 800,000 students and placed over 611,000 in top companies likex
              Microsoft, Amazon, TCS, IBM, Google, etc. and aims to transform education by
              connecting colleagues and corporates.
            </p>
          </div>
          <div className="md:w-1/3 mt-6 md:mt-0">
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFounder;