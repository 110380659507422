"use client";

import React from "react";
import {
  ChartBarIcon,
  AcademicCapIcon,
  UserGroupIcon,
  BriefcaseIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/outline";
import { ReactComponent as Products } from "../../assets/Products.svg";
import { ReactComponent as Projects } from "../../assets/Projects.svg";
import { ReactComponent as Services } from "../../assets/Services.svg";
import { ReactComponent as Consulting } from "../../assets/Consulting.svg";
import { ReactComponent as Trainings } from "../../assets/Trainings.svg";

const businessData = [
  {
    title: "Products",
    description:
      "Simplify your resume experience with a user-friendly platform, offering tailored templates to boost your job applications.",
    icon: <Products className="w-14 h-14 text-custom-gold mb-2" />,
  },
  {
    title: "Trainings",
    description:
      "We provide specialized trainings in academic subjects, employability skills, and corporate development, tailored to enhance knowledge and career readiness.",
    icon: <AcademicCapIcon className="w-12 h-12 text-custom-gold mb-4" />,
  },
  {
    title: "Consulting",
    description:
      "We offer expert consulting services in leadership development, maturity models, metrics management, and more, designed to drive organizational growth and efficiency.",
    icon: <Consulting className="w-12 h-12 text-custom-gold mb-4" />,
  },
  {
    title: "Projects",
    description:
      "We execute projects across sectors like government, information technology, and business process outsourcing, delivering tailored solutions for complex challenges.",
    icon: <Projects className="w-12 h-12 text-custom-gold mb-4" />,
  },
  {
    title: "Services",
    description:
      "We provide resource augmentation, hire-train-deploy solutions, and recruitment services to fulfill diverse talent needs.",
    icon: <Services className="w-16 h-16 text-custom-gold mb-4" />,
  },
];

const Business = () => {
  return (
    <div className="flex flex-col items-center justify-center py-24">
      <h2 className="text-4xl font-pt-sans font-bold mb-20">Our Businesses</h2>

      <div className="flex flex-wrap justify-center gap-5 max-w-6xl">
        {businessData.map((business, index) => (
          <div
            key={index}
            className="flex w-[370px] flex-col items-center text-center [box-sizing:content-box]"
          >
            {business.icon}
            <h3 className="text-2xl font-pt-sans font-bold">
              {business.title}
            </h3>
            <p className="text-gray-500 leading-5 md:text-xl text-base font-normal font-pt-sans mt-2 px-2">
              {business.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Business;
