import MissionBg from "../../assets/mission-bg.png"
import MissionAsset from "../../assets/Mission.svg"


const Mission = () => {
  return (
    <img 
    src = {MissionAsset}
    />
    /*
    <div className="mt-12 mb-12">
      <div 
        className="mx-auto py-24 rounded-lg overflow-hidden bg-[#FDF2DF] bg-no-repeat bg-right bg-cover"
        style={{backgroundImage: `url(${MissionBg})`}}
      >
        <div className="flex flex-col md:flex-row md:items-start">
          <div className="md:w-3/4 max-w-[50%] ml-[5%] text-gray-800 p-9 md:p-12">
            <h3 className="text-3xl font-pt-sans mb-6 font-semibold mb-2">Our Mission</h3>
            <p className="text-sm font-pt-sans hidden md:block md:text-2xl">
            We strive to establish the most dependable
worldwide network for clients and partners, offering value through our goods and services.
Striving to responsibly generate value for all our stakeholders.
            </p>
          </div>
          <div className="md:w-1/3 mt-6 md:mt-0">
            
          </div>
        </div>
      </div>
    </div>
    */
  );
};

export default Mission;