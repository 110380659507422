import React from "react";
import Card from "./Card";
import Academic from "../../../assets/AcademicTraining.png";
import EmploymentSkills from "../../../assets/EmploymentSkills.png";
import Corporate from "../../../assets/CorporateTrainings.png";

const Trainings = () => {
  const cardData = [
    {
      title: "Academic Trainings",
      description:
        "We provide comprehensive academic training, including curriculum design, skill development, and assessment.",
      src: Academic,
    },
    {
      title: "Employability Skills",
      description:
        "We offer training for placement, emphasizing employability skills, interview preparation, and professional development.",
      src: EmploymentSkills,
    },
    {
      title: "Corporate Trainings",
      description:
        "We deliver corporate training focusing on leadership, team development, and performance enhancement strategies.",
      src: Corporate,
    },
  ];

  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-wrap justify-around">
        {cardData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            description={card.description}
            imageUrl={card.src}
          />
        ))}
      </div>
    </div>
  );
};

export default Trainings;
