import { Link } from "react-router-dom";
import Logo from "../../assets/logo.svg";
import Facebook from "../../assets/fb.svg";
import Insta from "../../assets/insta.svg";
import Twitter from "../../assets/twiter.svg";
import Linkedin from "../../assets/linkedin.svg";

const Footer = () => {
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="bg-[#424859]">
      <div className="container mx-auto">
        <div className="flex md:justify-between items-center md:flex-row md:gap-0 flex-col gap-8 py-8 px-8 md:py-20">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">CCC</span>
            <img alt="Company Logo" src={Logo} className="h-20 w-auto" />
          </Link>
          <nav className="flex space-x-4">
            <Link to="/" className="text-white" onClick={scrollTop}>
              Home
            </Link>
            <Link to="/about" className="text-white" onClick={scrollTop}>
              About Us
            </Link>
            <Link to="/contact" className="text-white" onClick={scrollTop}>
              Contact
            </Link>
          </nav>
          <div className="flex space-x-4">
            <Link
              //   to="https://www.facebook.com/yourfacebookpage"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Facebook} alt="Facebook" className="w-8 h-8" />
            </Link>
            <Link
              to="https://www.instagram.com/yourinstagrampage"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Insta} alt="Instagram" className="w-8 h-8" />
            </Link>
            <Link
              to="https://www.instagram.com/yourinstagrampage"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Twitter} alt="Instagram" className="w-8 h-8" />
            </Link>
            <Link
              to="https://www.instagram.com/yourinstagrampage"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Linkedin} alt="Instagram" className="w-8 h-8" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
