import React from "react";
import Card from "./Card";
import RazorHireImage from "../../../assets/products_razorhire.png";
import JayahoImage from "../../../assets/products_jayaho.png";
import SkillGraphImage from "../../../assets/products_skillgraph.png";

const Products = () => {
  const cardData = [
    {
      title: "RazorHire",
      description:
        "Simplify your resume experience with a user-friendly platform, offering tailored templates to boost your job applications.",
      src: RazorHireImage,
    },
    {
      title: "Jayaho",
      description:
        "A platform that empowers students with essential coding, math, and tech skills through innovative and competitive courses.",
      src: JayahoImage,
    },
    {
      title: "Skill Graph",
      description:
        "A platform that enhances students' competitive coding skills to strengthen their resumes with notable achievements.",
      src: SkillGraphImage,
    },
  ];

  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-wrap justify-around">
        {cardData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            description={card.description}
            imageUrl={card.src}
          />
        ))}
      </div>
    </div>
  );
};

export default Products;
