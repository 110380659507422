import HeroSection from "../components/home/HeroSection";
import Business from "../components/home/Business/Index";
import Affiliations from "../components/home/Affiliations";
import Founder from "../components/home/Founder";
import MediaAnnouncements from "../components/home/Media";
import Footer from "../components/shared/Footer";

const Home = () => {
  return (
    <div className="flex flex-col">
      <HeroSection />
      <Business />
      <Affiliations />
      <Founder />
      {/* <MediaAnnouncements/> */}
      <Footer />
    </div>
  );
};

export default Home;
