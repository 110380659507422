import TeamImage from '../../assets/team.png'
import TeamAsset from "../../assets/teamImage.svg"

const Team = () => 
{
    return(
      <img 
      src = {TeamAsset}
      />
      /*
        <div className="relative h-[533px] flex items-center justify-center">
        <img 
          src={TeamImage}
          alt="Team Image" 
          className="absolute inset-0 w-full object-cover"
        />
        <div className="relative z-10 text-left text-center mt-auto mb-12 max-w-4xl mx-auto">
         
          <p className="text-[28px] font-pt-sans font-bold leading-7 text-gray-100">
          We are a dedicated team of professionals delivering innovative education, consulting, and workforce solutions to drive success and growth.</p>
        </div>
      </div>
      */
    );
}

export default Team