import React from "react";
import Card from "./Card";
import FarmLands from "../../../assets/FarmLands.png";
import Construction from "../../../assets/Construction.png";
import CommercialLeasing  from "../../../assets/CommercialLeasing.png";

const Projects = () => {
  const cardData = [
    {
      title: "Farm Plots",
      description:
        "We specialize in farm plots, offering expert guidance on land acquisition and investment.",
      src: FarmLands,
    },
    {
      title: "Construction",
      description:
        "We support construction projects with land acquisition, project management, and regulatory compliance.",
      src: Construction,
    },
    {
      title: "Commercial Leasing",
      description:
        "We offer commercial realty services, specializing in property acquisition, leasing, and investment opportunities.",
      src: CommercialLeasing,
    },
  ];

  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-wrap justify-around">
        {cardData.map((card, index) => (
          <Card
            key={index}
            title={card.title}
            description={card.description}
            imageUrl={card.src}
          />
        ))}
      </div>
    </div>
  );
};

export default Projects;
